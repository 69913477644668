@font-face {
  font-family:'myriad-pro-light';
  src: url('../../fonts/myriad-pro-light.woff2') format("woff");
}

@font-face {
  font-family:'myriad-pro';
  src: url('../../fonts/myriad-pro.woff2') format("woff2");
}

@font-face {
  font-family:'myriad-pro';
  src: url('../../fonts/myriad-pro-italic.woff2') format("woff2");
  font-style: italic;
}

@font-face {
  font-family:'myriad-pro';
  src: url('../../fonts/myriad-pro-bold.woff2') format("woff2");
  font-weight: bold;
}
