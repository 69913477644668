@font-face {
  font-family: myriad-pro-light;
  src: url("myriad-pro-light.9dc9ca8a.woff2") format("woff");
}

@font-face {
  font-family: myriad-pro;
  src: url("myriad-pro.0ab74268.woff2") format("woff2");
}

@font-face {
  font-family: myriad-pro;
  src: url("myriad-pro-italic.7e76a5b1.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: myriad-pro;
  src: url("myriad-pro-bold.8d57916d.woff2") format("woff2");
  font-weight: bold;
}

/*# sourceMappingURL=index.85c6d3f5.css.map */
